!function (factory) {
  if (typeof module !== 'undefined') { module.exports = factory } else {
    define('app/helper/browsercheck',[
      'bowser'
    ], factory)
  }
}(function (bowser) {

  /**
   * exposes method .check() which checks if the current browser is considered supported
   * redirects to a fixed url with the parameter "?r=[REASON]"
   * after successfully running check(), the global var SE_BROWSER_CHECKED is set TRUE which could be
   * used to e.g. disarm a timeout based failsafe
   *
   * @type {string}
   */

  var LOG_LABEL = 'browsercheck'

  var SE = window.SE,
    REDIRECT_ON_FAIL_URL = SE.BROWSER_NOT_SUPPORTED_URL,

    console = window.console || { error: function fn () { alert.apply(alert, arguments) } }

  var
    hasTouch = (('ontouchstart' in window) ||       // html5 browsers
      (navigator.maxTouchPoints > 0) ||   // future IE
      (navigator.msMaxTouchPoints > 0)),  // current IE10
    allowedBrowser = [ // based on "browser versions released end of 2016"
      { label: 'IE 11+', obj: { msie: '11' } },
      { label: 'IE Edge', obj: { msedge: '12' } },
      { label: 'Chrome 53+', obj: { chrome: '53' } },
      { label: 'Firefox 48+', obj: { firefox: '48' } },
      { label: 'Opera 23+', obj: { opera: '23' } },
      { label: 'Safari 10+', obj: { safari: '10' } },
      { label: 'Samsung Internet 10+', obj: { samsungBrowser: '9' } },
      { label: 'Phantom 2', obj: { phantom: '2' }, hide: true }
    ],
    supportedBrowsersStr = ''

  for (var i in allowedBrowser) {
    if (!allowedBrowser[i].hide)
      supportedBrowsersStr += allowedBrowser[i].label + ', '
  }

  // dependencies
  if (!bowser) {
    (function (err) {
      console.error(LOG_LABEL, err)
      alert(err)
    }('Browser check library "bowser" not found!'))

    return
  }

  if (document && document.body) {
    var userOs = bowser.linux ? 'linux' :
        bowser.windows ? 'windows' :
          bowser.android ? 'android' :
            bowser.ios ? 'ios' :
              bowser.mac ? 'macos' :
                bowser.xbox ? 'xbox' : 'unknown',

      userDeviceType = bowser.tablet ? 'tablet' :
        bowser.mobile ? 'mobile' : 'desktop',

      hasTouch = (hasTouch ? 'has' : 'no') + '-touch'

    try {
      document.body.classList.add.apply(document.body.classList, [
        'ua-' + bowser.name.toLowerCase(),
        'os-' + userOs,
        'os-v-' + bowser.osversion || 'unknown',
        'dev-' + userDeviceType,
        hasTouch
      ])
    } catch (err) {
      console.warn(LOG_LABEL, err)
    }
  }

  var
    unsupportedRedirect = function (message, bowser) {

      console.error(LOG_LABEL, message, { browser: [bowser.name, bowser.version, bowser], expected: allowedBrowser })

      // redirect to "not supported" page.
      window.location.href = REDIRECT_ON_FAIL_URL + '?r=' + bowser.name + '-' + bowser.version

    },
    createWarningFallback = function (title, textArrOrStr, button) {

      alert(
        '** ' + title + ' **' + '\n'
        + '\n'
        + (typeof textArrOrStr === 'object' ? textArrOrStr.join('\n') : textArrOrStr)
      )
    }

  var RESULT_UNSUPPORTED = 'unsupported browser',
    RESULT_BAD_VERSION = 'unsupported version',
    RESULT_SUPPORTED = 'supported browser version'

  /**
   *
   * @param params object
   * @param params.allowed array of e.g. {label: "IE 11+", obj: {msie: "11"}},
   * @returns {Browsercheck}
   * @constructor
   */
  var
    Browsercheck = function (params) {
      var _this = {}

      params = params || {}
      params.allowed = params.allowed || []

      function isSupportedBrowser () {
        var resBrowser = false,
          resVersion = false

        for (var i in params.allowed) {
          for (var browser in params.allowed[i].obj) {
            resBrowser = resBrowser || bowser[browser]
          }

          resVersion = resVersion || bowser.check(params.allowed[i].obj, true)
        }

        // HACK TO SUPPORT PHANTOMJS PRETENDING TO BE FF22
        if (bowser.name === 'Firefox' && bowser.version === 22) {
          resVersion = true
        }

        if (resVersion) {
          return RESULT_SUPPORTED
        } else if (resBrowser && !resVersion)
          return RESULT_BAD_VERSION
        else
          return RESULT_UNSUPPORTED
      }

      function displayWarning (title, text, buttonText, alertFn) {
        alertFn = alertFn || createWarningFallback

        alertFn(title, text, buttonText)

      }

      _this.check = function (options) {

        options = options || {}

        var alertFn = options.alertFn || createWarningFallback

        var res = isSupportedBrowser()

        SE.BROWSER = bowser

        switch (res) {
          case RESULT_UNSUPPORTED:

            // displayWarning(
            //     "Browser not supported",
            //     [
            //         "Your browser '" + bowser.name + " " + bowser.version + "' is not supported by this application.",
            //         "You may experience incorrect output, poor performance or a broken display. ",
            //         "Consider using a recent version of " + supportedBrowsersStr + "."
            //     ],
            //     "Ignore",
            //     alertFn
            // );
            console.warn(LOG_LABEL, 'browser not supported!', bowser)

            break
          case RESULT_BAD_VERSION:

            // displayWarning(
            //     "Browser Version not supported",
            //     [
            //         "The version '" + bowser.version + "' of your browsers '" + bowser.name + "' is not supported by this application.",
            //         "You may experience incorrect output, poor performance or a broken display. ",
            //         "Consider using a recent version of " + supportedBrowsersStr + "."
            //     ],
            //     "Ignore",
            //     alertFn
            // );

            console.warn(LOG_LABEL, 'browser version not supported!', bowser)

            break
          case RESULT_SUPPORTED:
            break
        }

        return res
      }

      _this.browser = bowser

      _this.failed = function () {
        unsupportedRedirect('Browser not supported', bowser)
      }

      return _this

    }

  return Browsercheck({ allowed: allowedBrowser })

})
;
