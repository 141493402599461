require([
    'app/helper/browsercheck'
], function (
    browsercheck
) {


    try{
        // only runs the browser check
        browsercheck.check();
    }catch(err){
        console.error("Browsercheck failed.", err);

    }

});
define("seIndex", function(){});

